html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(10, 11, 14) 18%,
    rgba(26, 29, 37, 1) 36%,
    rgba(14, 17, 26, 1) 63%
  );
  display: flex;
  align-items: center;
  justify-self: center;
}

.all-2d-html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #0e111a;
  z-index: 1;
}

.overlay-only {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  display: none;
  z-index: 1;
  opacity: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.small-screen-message {
  display: none;
}

@media only screen and (max-width: 630px) {
  .overlay-only {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0e111a;
    display: block;
    z-index: 10;
    opacity: 1;
  }
  .small-screen-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .small-screen-message-text {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 22px;
    text-align: center;
    width: 75%;
  }
}
