@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Poppins:wght@100;200;400&display=swap");

.loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 50%;
  margin-top: 270px; /** Need to be assessed for each device view-port **/
}

.loading-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.to-load {
  position: absolute;
  height: 0.9px;
  width: 333px;
  background-color: rgb(108, 108, 108);
}

.load-percentage {
  position: absolute;
  height: 1.8px;
  width: 0px;
  background-color: rgb(255, 255, 255);
}

.header {
  height: 27%;
  width: 100%;
  display: flex;
}
.header-left {
  width: 27%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.header-right {
  width: 27%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.name-span {
  width: 36%;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 253, 253, 0.87);
  font-weight: 400;
  font-size: 13.5px;
}

.name-span-2 {
  opacity: 0;
}
.name-span-1 {
  display: flex;
  flex-direction: row;
}

.header-center {
  width: 46%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  visibility: hidden;
}

.welcome-bold {
  color: #fffff9de;
  font-size: 27px;
  font-family: "Poppins", sans-serif;
  padding: 9px;
}

.welcome-translate {
  color: #fffdf3de;
  font-size: 17.1px;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  padding: 9px;
}

.center {
  width: 100%;
  height: 63%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.breathing-guide {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tetradecagon {
  height: 27%;
  width: auto;
  position: absolute;
}

.unjoined {
  z-index: 1;
}

.breathing-guide-text {
  position: absolute;
  color: #fff;
  z-index: 2;
  font-family: "Comfortaa", cursive;
}

.audio-selection {
  color: #fff;
  opacity: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}

.audio-answer {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  color: #c3c3c3;
  width: 40%;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.audio-question {
  font-size: 11px;
  color: #9c9c9c;
  text-align: center;
  font-weight: 400;
}

.credits {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 9px;
  position: absolute;
  bottom: 2px;
  right: -3px;
  letter-spacing: 1px;
}
.credits-a {
  text-decoration: none;
  color: #c3c3c3;
  margin-right: 9px;
}

.button {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #bfbebe;
  transition: all 0.5s;
}

.button:hover {
  color: #ffffff;
  cursor: pointer;
}

.audio-button-1 {
  margin-top: 9px;
  cursor: pointer;
  letter-spacing: 1px;
  border-bottom: 0.2px solid #c3c3c3;
}

.audio-button-1:hover {
  border-bottom: 0.2px solid #fff;
}

.tabletscreen iframe {
  width: 1410px;
  height: 1000px;
  border: none;
  border-radius: 20px;
  background: #000000;
}

.for-vision {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  opacity: 0;
  display: flex;
  flex-direction: row;
}

.for-vision-column {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33.33%;
  flex-direction: column;
}
.vision-para {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 200;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bold-letters {
  color: #f2e8ff;
  font-weight: 900;
  margin-left: 3px;
  margin-right: 3px;
}

.keypad {
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  height: 100%;
}

.key {
  height: 27px;
  width: 27px;
  border: 0.5px solid #bfbebe;
  margin: 9px;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.key-rep {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13.5px;
}

.key-instr {
  height: 25%;
  padding-right: 9px;
  background-color: #0000006e;
}
@media only screen and (max-height: 450px) {
  .breathing-guide-text {
    position: absolute;
    color: #fff;
    z-index: 2;
    font-family: "Comfortaa", cursive;
    font-size: 7px;
  }
  .welcome-bold {
    color: #fffff9de;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    padding: 6px;
  }

  .welcome-translate {
    color: #fffdf3de;
    font-size: 12px;
    font-weight: 200;
    font-family: "Poppins", sans-serif;
    padding: 6px;
  }
  .name-span {
    width: 36%;
    font-family: "Poppins", sans-serif;
    color: rgba(255, 253, 253, 0.87);
    font-weight: 400;
    font-size: 9px;
  }
}

@media only screen and (min-width: 630px) and (max-width: 900px) {
  .loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 50%;
    margin-top: 120px; /** Need to be assessed for each device view-port **/
  }

  .breathing-guide-text {
    position: absolute;
    color: #fff;
    z-index: 2;
    font-family: "Comfortaa", cursive;
    font-size: 7px;
  }

  .center {
    width: 100%;
    height: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .key {
    height: 12px;
    width: 12px;
    border: 0.5px solid #bfbebe;
    margin: 7px;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .key-rep {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 9px;
  }
}
